
import { defineComponent, ref, onMounted } from 'vue'
import { getIncubate, getDict } from '@/api/home'

export default defineComponent({
  setup() {
    const active = ref(0)
    const tabList = ref()
    const list = ref(null)
    const params = {
      type: ''
    }
    onMounted(async () => {
      getIncubateFunc()
      getDictFunc()
    })
    const getIncubateFunc = async () => {
      const data = await getIncubate(params)
      list.value = data.data
    }
    const getDictFunc = async () => {
      const data = await getDict('CASE_TYPE')
      console.log(data)
      tabList.value = data.data
      tabList.value.unshift({
        label: '全部',
        value: ''
      })
    }
    const changeTab = (id: string, i: number) => {
      active.value = i
      params.type = id
      getIncubateFunc()
    }
    return {
      list,
      params,
      tabList,
      changeTab,
      active
    }
  }
})
